* {
    font-family: Arial, Helvetica, sans-serif;
    scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}
::-webkit-scrollbar {
    width: 10px;
    background: #f4f4f4;
}
::-webkit-scrollbar-thumb {
    background: #dad7d7;
}

body {
    background-image: url(./assets/drakkar-background.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: gray;
    background-blend-mode: soft-light;
    font-family: Roboto, sans-serif;
}

html {
    height: 100%;
}

a {
    text-decoration: none;
    color: black;
}

.subtotalPrice {
    font-family: Roboto, sans-serif;
    font-size: 28px;
    letter-spacing: 1.2px;
    font-weight: 400;
    display: grid;
    justify-content: center;
    align-items: center;
}

h3 {
    font-family: Roboto, sans-serif;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 20px;
}

.logo {
    max-width: 3rem;
    filter: invert(47%) sepia(100%) saturate(5491%) hue-rotate(339deg)
        brightness(94%) contrast(92%);
}
.logo-nav {
    font-family: Roboto;
    max-width: 2.3rem;
    transform: translateY(3.1px);
    filter: invert();
}
.logo-title-nav {
    font-weight: 600;
    letter-spacing: 0.8px;
    padding-left: 3rem;
    padding-right: 0;
    margin-right: 0;
    font-family: 'Roboto';
}

.nav {
    font-weight: 300;
    transition: all 0.3s ease;
    border: none;
    background: none;
}

@media only screen and (max-width: 1452px) {
    .div-invisibility {
        display: none;
    }
}
.nav span {
    padding-bottom: 7px;
    letter-spacing: 4px;
    padding-right: 15px;
}

.nav-underline {
    color: #fff;
    padding: 1rem;
    margin: 0.5rem;
    letter-spacing: 1px;
    position: relative;
    text-transform: Capitalize;
    font-family: 'Roboto';
    font-weight: 'bold';
}

.nav-underline:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.nav:hover .nav-underline:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

ul {
    list-style-image: url('./assets/red-dot.png');
}

li {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    text-indent: 10px;
    font-weight: 300;
    padding-bottom: 15px;
    width: 90%;
}

li::marker {
    padding-right: 2rem;
    font-size: 0.8px;
}

li span {
    font-weight: 300;
}

#root
    > div
    > div.edit-proposal-tablestyles__Container-sc-1njo6qv-0.dQXUOo
    > div
    > div
    > div.MuiTableContainer-root
    > table
    > thead
    > tr
    > th
    > span {
    font-family: 'Roboto' !important;
    color: #444446 !important;
    font-weight: 500 !important;
    font-size: 13.5px;
    line-height: 17px !important;
    text-align: center;
    transform: translateX(-5px);
    /* text-transform: capitalize !important; */
}

#demo-simple-select-helper {
    font-size: 14px;
}
#root
    > div
    > div.edit-proposal-tablestyles__Container-sc-1njo6qv-0.dQXUOo
    > div
    > div
    > div.MuiTableContainer-root
    > table
    > tbody
    > tr
    > td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter {
    transform: translateX(-1rem);

    color: #000;
    font-weight: 300;
    text-transform: capitalize;
}
#root
    > div
    > div.dashboard-datastyles__Container-sc-1vlnikt-0.fFwtXu
    > div.dashboard-datastyles__ContainerRow-sc-1vlnikt-1.klmlHU
    > div.dashboard-datastyles__Column2-sc-1vlnikt-3.QbeRJ
    > div
    > div.dashboard-datastyles__Chart-sc-1vlnikt-6.jvQIfq
    > div
    > svg
    > g
    > g.recharts-layer.recharts-pie-labels
    > g
    > text {
    font-family: 'Roboto';
    font-weight: 500;
}

/* Seletor da porcentagem branca do dashboard */
#root
    > div
    > div.dashboard-datastyles__Container-sc-1vlnikt-0.fFwtXu
    > div.dashboard-datastyles__ContainerRow-sc-1vlnikt-1.klmlHU
    > div.dashboard-datastyles__Column2-sc-1vlnikt-3.QbeRJ
    > div
    > div.dashboard-datastyles__Chart-sc-1vlnikt-6.jvQIfq
    > div
    > svg
    > g
    > g.recharts-layer.recharts-pie-labels
    > g:nth-child(3)
    > text {
    fill: rgb(109, 109, 109);
    font-weight: 700;
}

#root
    > div
    > div.makeStyles-subContainer-1199
    > div
    > div:nth-child(2)
    > div:nth-child(6)
    > button {
    transform: translateY(-3rem) scale(0.8);
}
